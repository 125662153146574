<script setup>
import {ref} from "vue";

/**
 * Data
 */
const tabs = ref([
	{
		label: 'Lots',
		to: {name: "lots-calculator"}
	},
	{
		label: 'Order',
		to: {name: "order-calculator"}
	},
])


/**
 * Computed
 */


/**
 * Mounted
 */


/**
 * Actions
 */

</script>

<style lang="scss" scoped>
::v-deep .p-card-body {
	padding: 2rem;
}
::v-deep.advice-card .p-card-body {
	padding: 1rem 1rem 0 1rem;
}
</style>

<template>
	<TabMenu :model="tabs" />
	<router-view/>
</template>
